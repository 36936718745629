import React, { useState } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";
import { images } from "../../constants";

const Footer = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

  // destructuring datas
  const {name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value});
  }

  const handleSubmit = () => {
    setLoading(true)

    const contact = {
      _type:'contact',
      name: name,
      email:email,
      message:message,
    }
    client.create(contact)
    .then(() => {
      setLoading(false)
      setIsFormSubmitted(true)
    })
  }
	return (
		<>
			<h2 className="head-text">Contact</h2>
			<div className="app__footer-cards">
				<div className="app__footer-card">
					<img src={images.mail} alt="email" />
					<a href="mailto:m27sylla@gmail.com" className="p-text">
						Send me an Email
					</a>
				</div>
			</div>

			{!isFormSubmitted ? (
				<div className="app__footer-form app__flex">
					<div className="app__flex">
						<input
							type="text"
							className="p-text"
							placeholder="Your Name"
							name="name"
							value={name}
							onChange={handleChangeInput}
						/>
					</div>

					<div className="app__flex">
						<input
							type="email"
							className="p-text"
							placeholder="Your Email"
							name="email"
							value={email}
							onChange={handleChangeInput}
						/>
					</div>
					<div>
						<textarea
							className="p-text"
							placeholder="Your Message"
							value={message}
							name="message"
							onChange={handleChangeInput}
						/>
					</div>

					<button type="button" className="p-text" onClick={handleSubmit}>
						{loading ? "Sending" : "Send Message"}
					</button>
				</div>
			) : (
				<div>
					<h3 className="head-text">Thank you for getting in touch!</h3>
				</div>
			)}
			<div className="copyright">
				<p className="p-text">@2022 MAMADOU</p>
				<p className="p-text">All rights reserved</p>
			</div>
		</>
	);
};

export default AppWrap(
	MotionWrap(Footer, "app__footer"),
	"contact",
	"app__whitebg"
);
